header {
  background-color: var(--footer-color);
  padding: 10px;
  color: var(--text-color);
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

.menu {
  background-color: var(--menu--color);
}
nav {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  overflow: hidden;
}

nav a {
  text-decoration: none;
  color: var(--accent-contrast-color);
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

nav a:hover {
  background-color: #555;
}

.burger-menu {
  display: none;
  cursor: pointer;
}

main {
  padding: 0 20px 20px 0;
  margin-bottom: 120px;
  margin-top: 120px;
  background-color: var(--bg-color);
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.level-selector {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Горизонтальный режим, блоки переносятся на новую строку при нехватке места */
  padding-bottom: 10px;
}

.level-card {
  background-color: var(--button-color);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s;
  box-sizing: border-box;
  width: 150px;
  color: var(--text-color);
}

.level-card:hover {
  transform: scale(1.05);
}

footer {
  background-color: var(--footer-color);
  /* padding: 10px; */
  height: 120px;
  color: var(--text-color);
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.friend-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  /* margin-top: 10px; */
}

.friend-card {
  text-align: center;
  margin: 10px;
}
.friend-card p {
  margin-block-start:0;
  margin-block-end:0;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media Query for responsive design */
@media (max-width: 768px) {
  nav {
      flex-direction: column;
      align-items: center;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
  }

  nav.show {
      max-height: 500px; /* Adjust the max-height as needed */
  }

  nav a {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
  }

  .burger-menu {
      display: block;
  }

  nav:not(.show) .burger-menu {
      display: none;
  }

  main {
      margin-top: 100px;
  }
}