body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color); /* Цвет фона страницы */
  color: var(--text-color); /* Цвет текста */
  transition: background-color 0.5s, color 0.5s;
}
/* Светлая тема */
:root {
    --bg-color: #f0f0f0;
    --text-color: #404040;
    --button-color: #d0d0d0;
    --accent-color: #4CAF50;
    --accent-contrast-color: #f0f0f0;
    --menu--color: #999;
    --footer-color: #ada;
  } 
/* Темная тема */
@media (prefers-color-scheme: dark) {
    :root {
        --bg-color: #202020;
        --text-color: #c0c0c0;
        --button-color: #404040;
        --accent-color: #4CAF50;
        --accent-contrast-color: #f0f0f0;
        --menu--color: #444;
        --footer-color: #333;
      }
}

/* Общие стили для контейнеров */
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden; /* Предотвращаем горизонтальную полосу прокрутки */
}

.return-button {
    float: right;
    margin-top: -40px;
    margin-right: 20px;
    background-color: var(--accent-color);
    color: var(--accent-contrast-color);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
} 

.player-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 20px;
}

.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #ddd;
    margin-bottom: 10px;
    position: relative;
}

.progress-bar-fill {
    height: 100%;
    width: 60%;
    background-color: var(--accent-color); /* Цвет заполнения */
    position: absolute;
    top: 0;
    left: 0;
}

.health-bar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.answer-button {
    background-color: var(--accent-color);
    color: var(--accent-contrast-color);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

/* Вертикальная ориентация */
@media (max-width: 767px) {
  .level {
      text-align: center;
      font-size: 24px;
      margin-bottom: 10px;
  }

  .player-image {
      max-height: 400px;
  }

  .heart {
      font-size: 24px;
      margin-right: 5px;
      color: #cdcdcd; /* Цвет сердец по умолчанию */
  }

  .answer-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
  }
}

/* Горизонтальная ориентация */
@media (min-width: 768px) {
  .game-container {
      display: flex;
  }

  .left-column, .right-column {
      flex: 1;
      padding: 10px;
  }

  .level {
      font-size: 32px;
  }

  .player-image {
      max-width: 100%;
      max-height: 80vh; /* Новое ограничение высоты для горизонтальной ориентации */
  }

  .heart {
      font-size: 30px;
      margin-right: 10px;
      color: #ff8080; /* Цвет сердец по умолчанию */
  }

  .answer-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
}